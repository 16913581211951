import { useState, useEffect, useRef } from "react";
import NavBar from "./NavBar";
import { useDispatch, useSelector } from "react-redux";
import { anomalieActions } from "../store/actions/anomalies.actions";
import { MDBBtn } from "mdb-react-ui-kit";
import concIcon from "../assets/icons/conc.svg";
import doneIcon from "../assets/icons/done.svg";
import expirIcon from "../assets/icons/expir.svg";
import infoIcon from "../assets/icons/info.svg";
import pendIcon from "../assets/icons/pend.svg";
import warnIcon from "../assets/icons/warn.svg";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import AnomaliesPopUp from "./AnomaliesPopUp";
import PaginationCustom from "./PaginationCustom";
import PaginationPageNum from "./PaginationPageNum";
import SelectOptionDropDown from "./SelectOptionDropDown";
import moment from "moment";
import { handleLongString } from "../helpers/Helper";
import { handleLongDateString } from "../helpers/Helper";
import redIcon from "../assets/icons/Urgent.png";
import yellowIcon from "../assets/icons/Not_urgent.png";
import greenIcon from "../assets/icons/Not_applicable.png";
import AnomalyPopUp from "./secondAnomaliesPopup";
import SideBar from "./SideBar";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { AnomaliesStatusFilter } from "../components/AnomaliesSelectors/anomaliesStatusFilter";
import { SelectedDate } from "../components/AnomaliesSelectors/selectedDate";
import { Contractor } from "../components/AnomaliesSelectors/contractor";
import { Type } from "../components/AnomaliesSelectors/type";
import { Modal } from "../components/modals/Anomaly";
// import { AnomaliesContractor } from "../components/AnomaliesSelectors/contractor";

function Anomalies() {
  const options = ["John Paul", "David Fincher", "Willie Engelbrecht"];
  const [page, setPage] = useState(1);
  const AnomaliesStatus = window.location.href.split("status=")[1];

  const updatePageNo = (newState) => {
    setPage(newState);
  };
  const companyList = [
    {
      label: "Red Top Asset Management",
      value: "Red Top Asset Management",
      type: "anmComapny",
    },
    {
      label: "Quartz Plant Hire",
      value: "Quartz Plant Hire",
      type: "anmComapny",
    },
    {
      label: "KLT Machinery and Plant Hire",
      value: "KLT Machinery and Plant Hire",
      type: "anmComapny",
    },
  ];
  const dispatch = useDispatch();
  const siteTypelist = useSelector((state) => state.AnomalieList.site_type);
  const documentList = useSelector((state) => state.AnomalieList.document_type);
  const statuslist = useSelector((state) => state.AnomalieList.status);
  const total = useSelector((state) => state.AnomalieList.total);
  const offset = useSelector((state) => state.AnomalieList.offset);
  const upto = useSelector((state) => state.AnomalieList.upto);
  const anomalieslistloading = useSelector(
    (state) => state.AnomalieList.anomalie_loading
  );
  const anomalieData = useSelector((state) => state.AnomalieList.anomalie_data);
  const auditsData = useSelector((state) => state.AnomalieList.audits_data);
  const no_of_pages = useSelector((state) => state.AnomalieList.no_of_pages);

  const [selectdMonth, setSelectdMonth] = useState(null);
  const [selectstatus, setSelectedStatus] = useState(null);
  const [anomalieDataList, setAnomalieData] = useState(null);
  const [siteType, setSiteType] = useState(null);
  const [docType, setDocType] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [anomaliePopUp, setAnomaliePopUp] = useState(false);

  const openAnomaliePopUp = (anomalie_data) => {
    setAnomaliePopUp(true);
    setAnomalieData(anomalie_data);
  };

  const hideAnomaliePopUp = () => {
    setAnomaliePopUp(false);
  };


  const today = new Date();
  // const startDate = new Date();
  // startDate.setDate(today.getDate() - calenderDays);
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1);

  const svg = (
    <svg
      width="40"
      height="28"
      viewBox="0 2 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8125 27.125L7.4375 23.0625L2.71875 22.0938L3.25 17.5L0.25 14L3.25 10.5312L2.71875 5.9375L7.4375 4.96875L9.8125 0.875L14 2.8125L18.1875 0.875L20.5938 4.96875L25.2812 5.9375L24.75 10.5312L27.75 14L24.75 17.5L25.2812 22.0938L20.5938 23.0625L18.1875 27.125L14 25.1875L9.8125 27.125ZM10.6562 24.6562L14 23.25L17.4375 24.6562L19.5312 21.5312L23.1875 20.5938L22.8125 16.875L25.3438 14L22.8125 11.0625L23.1875 7.34375L19.5312 6.46875L17.375 3.34375L14 4.75L10.5625 3.34375L8.46875 6.46875L4.8125 7.34375L5.1875 11.0625L2.65625 14L5.1875 16.875L4.8125 20.6562L8.46875 21.5312L10.6562 24.6562ZM12.6562 18.1562L19.75 11.125L18.3438 9.84375L12.6562 15.4688L9.6875 12.375L8.25 13.7812L12.6562 18.1562Z"
        fill="#1DAD2C"
      />
    </svg>
  );

  // const renderPageNumbers = () => {
  //     const elements = [];
  //     for (let i = 1; i <= no_of_pages; i++) {
  //         elements.push(
  //             <PaginationPageNum
  //                 key={i}
  //                 active={page === i}
  //                 onClick={() => setPage(i)}
  //             >
  //                 {i}
  //             </PaginationPageNum>
  //         );
  //     }
  //     return elements;
  // };

  const handlePageChange = (pageNo) => {
    console.log("page....", pageNo)

    if (pageNo === "-") {
      console.log("+++++", pageNo)

      if (page > 1) {
        dispatch(
          anomalieActions.getAnomalielist(
            localStorage.getItem("site") ? localStorage.getItem("site") : null,
            localStorage.getItem("document")
              ? localStorage.getItem("document")
              : null,
            localStorage.getItem("anm_status")
              ? localStorage.getItem("anm_status")
              : null,
            localStorage.getItem("anm_start_date")
              ? localStorage.getItem("anm_start_date")
              : null,
            localStorage.getItem("anm_end_date")
              ? localStorage.getItem("anm_end_date")
              : null,
            localStorage.getItem("anmComapny")
              ? localStorage.getItem("anmComapny")
              : null,
            page - 1,
            localStorage.getItem("anom_search")
              ? localStorage.getItem("anom_search")
              : null
          )
        );
      }
    }

    if (pageNo === "+") {
      console.log("+++++", pageNo)
      dispatch(
        anomalieActions.getAnomalielist(
          localStorage.getItem("site") ? localStorage.getItem("site") : null,
          localStorage.getItem("document")
            ? localStorage.getItem("document")
            : null,
          localStorage.getItem("anm_status")
            ? localStorage.getItem("anm_status")
            : null,
          localStorage.getItem("anm_start_date")
            ? localStorage.getItem("anm_start_date")
            : null,
          localStorage.getItem("anm_end_date")
            ? localStorage.getItem("anm_end_date")
            : null,
          localStorage.getItem("anmComapny")
            ? localStorage.getItem("anmComapny")
            : null,
          page + 1
        )
      );
    }
  };

  const [endDate, setEndDate] = useState("");
  const [placeholder, setplaceholder] = useState("Date");

  const handleCallback = (start, end, label) => {
    setPage(1);
    let s_date = null;
    let e_date = null;

    // setStartDate(moment(start).format("YYYY-MM-DD"));
    s_date = moment(start).format("YYYY-MM-DD");
    setplaceholder(
      `${moment(start).format("YYYY-MM-DD")}~${moment(end).format(
        "YYYY-MM-DD"
      )} `
    );
    localStorage.setItem("anm_start_date", moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DDY"));
    e_date = moment(end).format("YYYY-MM-DD");
    localStorage.setItem("anm_end_date", moment(end).format("YYYY-MM-DD"));
    dispatch(
      anomalieActions.getAnomalielist(
        null,
        null,
        localStorage.getItem("anm_status")
          ? localStorage.getItem("anm_status")
          : null,
        s_date,
        e_date,
        localStorage.getItem("anmComapny")
          ? localStorage.getItem("anmComapny")
          : null,
        1,
        localStorage.getItem("anom_search")
          ? localStorage.getItem("anom_search")
          : null
      )
    );
  };
  const [reset, setReset] = useState(false);

  const HandleClearFilters = () => {
    handleReset();
    setDateRange({
      startDate,
      endDate: today,
      maxDate: today,
    });
    HandleSearchChange2("");
    setplaceholder("Date");
    setReset(!reset);
    dispatch(
      anomalieActions.getAnomalielist(
        null,
        null,
        null,
        null,
        null,
        null,
        page,
        null
      )
    );
    localStorage.removeItem("anom_search");
    localStorage.removeItem("site");
    localStorage.removeItem("document");
    localStorage.removeItem("anm_status");
    localStorage.removeItem("anm_start_date");
    localStorage.removeItem("anm_end_date");
    localStorage.removeItem("anmComapny");
    handleCallback(startDate, today);

  };

  // const handlePageChange2 = (pageNo) => {
  //   console.log("page....here 2", page)
  //   setPage(pageNo);
  //   anomalieActions.getAnomalielist(
  //     localStorage.getItem("site") ? localStorage.getItem("site") : null,
  //     localStorage.getItem("document")
  //       ? localStorage.getItem("document")
  //       : null,
  //     localStorage.getItem("anm_status")
  //       ? localStorage.getItem("anm_status")
  //       : null,
  //     localStorage.getItem("anm_start_date")
  //       ? localStorage.getItem("anm_start_date")
  //       : null,
  //     localStorage.getItem("anm_end_date")
  //       ? localStorage.getItem("anm_end_date")
  //       : null,
  //     localStorage.getItem("anmComapny")
  //       ? localStorage.getItem("anmComapny")
  //       : null,
  //     pageNo
  //   );
  //   console.log("page....here 2 updated", page)
  // };


  const handlePageChange2 = (pageNo) => {
    setPage(pageNo);
    dispatch(
      anomalieActions.getAnomalielist(
        localStorage.getItem("site") ? localStorage.getItem("site") : null,
        localStorage.getItem("document") ? localStorage.getItem("document") : null,
        localStorage.getItem("anm_status")
          ? localStorage.getItem("anm_status")
          : null,
        localStorage.getItem("anm_start_date")
          ? localStorage.getItem("anm_start_date")
          : null,
        localStorage.getItem("anm_end_date")
          ? localStorage.getItem("anm_end_date")
          : null,
        localStorage.getItem("anmComapny")
          ? localStorage.getItem("anmComapny")
          : null,
        pageNo,
        localStorage.getItem("anom_search")
      )
    );
  };

  const renderPageNumbers = () => {
    const elements = [];
    let new_no_of_pages = 1;
    let page_start = 1;
    if (no_of_pages > 4) {
      page_start = page;
      new_no_of_pages += 2 + page;
      if (new_no_of_pages >= no_of_pages) {
        new_no_of_pages = no_of_pages;
      }
      let page_diff = no_of_pages - page_start;

      if (page_diff <= 3) {
        page_start = no_of_pages - 4;
      }
    } else {
      new_no_of_pages = no_of_pages;
    }

    for (let i = page_start; i <= new_no_of_pages; i++) {
      elements.push(
        <PaginationPageNum
          key={i}
          active={page === i}
          onClick={() => handlePageChange2(i)}
        >
          {i}
        </PaginationPageNum>
      );
    }
    return elements;
  };


  const handleclosepopup = (rslt) => {
    setAnomaliePopUp(rslt);
  };

  const [open, setOpen] = useState(false);
  const openAnomalieModal = (anomalie_data) => {
    setOpen(true);
    setAnomalieData(anomalie_data);
  };
  const [searchItem, setSearchitem] = useState("");
  const [searchValue, setSearchValue] = useState(null);

  const HandleSearchChange2 = (search) => {
    setSearchitem(search);
    setSearchValue(search);
  };
  const HandleSearchChange = (search) => {
    localStorage.setItem("anom_search", search);
    setSearchitem(search);
    setPage(1);
    setSearchValue(search);
    dispatch(
      anomalieActions.getAnomalielist(
        localStorage.getItem("site") ? localStorage.getItem("site") : null,
        localStorage.getItem("document")
          ? localStorage.getItem("document")
          : null,
        localStorage.getItem("anm_status")
          ? localStorage.getItem("anm_status")
          : null,
        localStorage.getItem("anm_start_date")
          ? localStorage.getItem("anm_start_date")
          : null,
        localStorage.getItem("anm_end_date")
          ? localStorage.getItem("anm_end_date")
          : null,
        localStorage.getItem("anmComapny")
          ? localStorage.getItem("anmComapny")
          : null,
        1,
        localStorage.getItem("anom_search")
          ? localStorage.getItem("anom_search")
          : null
      )
    );
  };

  const [dateRange, setDateRange] = useState({
    startDate,
    endDate: today,
    maxDate: today,
  });
  const dateRangePickerRef = useRef(null);
  const [selectedRange, setSelectedRange] = useState(null);

  const handleReset = () => {
    const { current: picker } = dateRangePickerRef;
    if (picker) {
      picker.setStartDate(today);
      picker.setEndDate(today);
      setSelectedRange(null);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user_role") !== "CFO") {
      window.location.assign("/");
    }

    const st_date = moment(startDate).format("YYYY-MM-DD");
    const et_date = moment(today).format("YYYY-MM-DD");

    if (AnomaliesStatus) {
      dispatch(
        anomalieActions.getAnomalielist(
          siteType,
          docType,
          "Urgent",
          st_date,
          et_date,
          null,
          page,
          null
        )
      );
    } else {
      dispatch(
        anomalieActions.getAnomalielist(
          siteType,
          docType,
          statusType,
          st_date,
          et_date,
          null,
          page,
          null
        )
      );
    }
    localStorage.removeItem("site");
    localStorage.removeItem("document");
    localStorage.removeItem("anm_status");
    localStorage.removeItem("anm_start_date");
    localStorage.removeItem("anm_end_date");
    localStorage.removeItem("anmComapny");
    localStorage.removeItem("anom_search");
    handleCallback(startDate, today);
    setReset(false);
  }, [reset]);
  return (
    <div className="flex">
      <Modal open={open} setOpen={setOpen} anomalie_data={anomalieDataList} />
      <SideBar />
      <main className="w-full min-w-[70rem] overflow-x-auto bg-gray-100 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center">
              <div className="space-y-4 sm:flex-auto">
                <h1 className="w-96 text-3xl font-semibold leading-6 text-gray-900">
                  Anomalies
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  Here's an overview of your Anomalies
                </p>
              </div>
              <div className="mt-4 lg:ml-16 lg:mt-0 lg:w-3/4 ">
                <div>
                  <div className="w-full max-w-xs lg:ml-auto">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="search-icon pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="hw-2 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="search-box block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                        onChange={(event) =>
                          HandleSearchChange(event.target.value)
                        }
                        value={searchValue}
                      />
                      {searchValue && (
                        <button
                          type="button"
                          className="cross-icon absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600 text-sm font-bold"
                          onClick={() => HandleSearchChange("")}
                        >
                          ✕
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5 flex items-center space-x-5">
              <Contractor
                contractorList={companyList && companyList}
                updatePageNo={updatePageNo}
              />
              <AnomaliesStatusFilter
                statusTypes={statuslist && statuslist}
                updatePageNo={updatePageNo}
              />
              <div className="date-range ">
                <DateRangePicker
                  ref={dateRangePickerRef}
                  onCallback={handleCallback}
                  initialSettings={dateRange}
                >
                  <button
                    type="button"
                    className="custom-select-container_calendar "
                  >
                    {handleLongDateString(placeholder, 12)}
                  </button>
                </DateRangePicker>
              </div>
              {/* <Type />
              <SelectedDate /> */}
              <button
                type="button"
                className="f-btn mt-2 inline-flex items-center gap-x-1.5 rounded-md bg-[#FCAF17] px-5 py-[0.45rem] text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FCAF17]"
                onClick={HandleClearFilters}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={14}
                  fill="none"
                >
                  <path
                    fill="#fff"
                    d="M5.25 14c-1.486-.194-2.733-.85-3.74-1.969C.503 10.913 0 9.583 0 8.041c0-.847.16-1.635.48-2.364.319-.73.756-1.365 1.312-1.906l1.062 1.062a4.464 4.464 0 0 0-.99 1.438A4.37 4.37 0 0 0 1.5 8.04c0 1.126.358 2.098 1.073 2.917A4.533 4.533 0 0 0 5.25 12.48V14Zm1.5 0v-1.52a4.414 4.414 0 0 0 2.677-1.511c.715-.827 1.073-1.802 1.073-2.927 0-1.25-.438-2.313-1.313-3.188C8.313 3.98 7.25 3.542 6 3.542h-.146l.958.958L5.75 5.542 3 2.792 5.75.042l1.063 1.062-.938.938H6c1.667 0 3.083.583 4.25 1.75C11.417 4.958 12 6.375 12 8.042c0 1.541-.504 2.875-1.51 4C9.483 13.167 8.236 13.819 6.75 14Z"
                  />
                </svg>
                Reset
              </button>
            </div>
            <ul role="list" className="my-8 grid w-full grid-cols-2 gap-8">
              {anomalieData && anomalieData.length > 0 ? (
                anomalieData.map((data) => (
                  <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                    <div
                      className="crsr-pointer flex w-full items-center justify-between space-x-6 p-6"
                      onClick={() => openAnomalieModal(data)}
                    >
                      {data.INV_PO_Price_Anomaly_Status === "Urgent" ? (
                        <div className="rounded-md bg-[#E34747] bg-opacity-20 p-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={60}
                            height={60}
                            fill="none"
                          >
                            <path
                              fill="#E34747"
                              d="M48.013 47.78c-.166-.836-.987-1.705-1.834-1.97-.515-.16-10.531-.823-16.2-.815-5.668-.008-15.684.656-16.199.815-.844.267-1.669 1.136-1.835 1.97a8.494 8.494 0 0 0-.12 2.108c.04.705.166 1.402.375 2.077.265.809 1.154 1.573 1.99 1.753.518.109 10.375.26 15.79.26 5.413 0 15.27-.151 15.784-.26.837-.18 1.725-.944 1.99-1.753a8.761 8.761 0 0 0 .376-2.078 8.507 8.507 0 0 0-.117-2.108ZM31.974 7.973v3.288c0 1.1-1.1 1.994-1.994 1.994a1.994 1.994 0 0 1-1.994-1.994V7.973c0-1.102 1.101-1.994 1.994-1.994 1.101 0 1.994.892 1.994 1.994ZM15.555 13.122l2.325 2.325c.779.779.631 2.188 0 2.82a1.994 1.994 0 0 1-2.82 0l-2.325-2.325c-.779-.78-.631-2.19 0-2.82a1.994 1.994 0 0 1 2.82 0ZM47.226 15.943 44.9 18.268c-.78.779-2.19.63-2.82 0a1.994 1.994 0 0 1 0-2.82l2.325-2.325c.779-.779 2.188-.631 2.82 0a1.994 1.994 0 0 1 0 2.82ZM51.985 32.363h-3.288c-1.1 0-1.994-1.101-1.994-1.994 0-1.102.893-1.994 1.994-1.994h3.288c1.102 0 1.995 1.1 1.995 1.994a1.994 1.994 0 0 1-1.995 1.994ZM11.26 32.362H7.974c-1.101 0-1.994-1.101-1.994-1.994 0-1.101.892-1.994 1.994-1.994h3.288c1.1 0 1.994 1.1 1.994 1.994a1.994 1.994 0 0 1-1.994 1.994ZM44.063 29.84c-.117-7.515-6.672-13.593-14.083-13.593-7.412 0-13.969 6.084-14.083 13.599-.037 4.37.042 8.74.236 13.11 1.057-.077 2.313-.158 3.612-.231 3.956-.224 7.652-.353 10.139-.353h.187c2.488 0 6.188.129 10.14.353 1.299.074 2.555.154 3.612.23.196-4.37.276-8.742.24-13.114Zm-17.048-5.554c-1.493.39-2.73 1.571-3.15 3.007a1.781 1.781 0 1 1-3.419-1c.777-2.653 2.949-4.742 5.667-5.453a1.781 1.781 0 1 1 .902 3.446Z"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="rounded-md bg-[#FCAF17] bg-opacity-20 p-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={60}
                            height={60}
                            fill="none"
                          >
                            <path
                              fill="#FCAF17"
                              d="M16.537 22.48c0 .175.068.34.192.463l1.074 1.075a15.094 15.094 0 0 1 2.732-2.9l-.99-.99a.656.656 0 0 0-.927 0l-1.89 1.89a.652.652 0 0 0-.191.462ZM29.978 17.76c.538 0 1.069.03 1.592.085v-2.049h-3.183v2.049a15.05 15.05 0 0 1 1.591-.085ZM42.153 24.018l1.075-1.075a.65.65 0 0 0 0-.925l-1.89-1.89a.655.655 0 0 0-.926 0l-.99.99a15.08 15.08 0 0 1 2.731 2.9ZM29.978 21.64c-6.123 0-11.106 4.982-11.106 11.106 0 6.123 4.983 11.106 11.106 11.106 6.124 0 11.106-4.982 11.106-11.106 0-6.125-4.982-11.106-11.106-11.106Zm0 17.656a1.903 1.903 0 1 1 0-3.807 1.903 1.903 0 0 1 0 3.807Zm1.955-6.862a1.066 1.066 0 0 1-1.064 1.012h-1.78a1.065 1.065 0 0 1-1.065-1.012l-.375-7.477a1.065 1.065 0 0 1 1.064-1.119h2.531c.61 0 1.095.51 1.064 1.119l-.375 7.477ZM26.381 15.034h7.195c.417 0 .757-.34.757-.756V11.99a.758.758 0 0 0-.757-.757h-7.195a.758.758 0 0 0-.756.757v2.288c0 .417.339.756.756.756Z"
                            />
                            <path
                              fill="#FCAF17"
                              d="M29.978 18.522c-7.843 0-14.224 6.381-14.224 14.224S22.135 46.97 29.978 46.97c7.844 0 14.225-6.38 14.225-14.224 0-7.843-6.381-14.224-14.225-14.224Zm0 26.092c-6.544 0-11.868-5.324-11.868-11.868 0-6.544 5.324-11.868 11.868-11.868 6.544 0 11.868 5.324 11.868 11.868 0 6.544-5.324 11.868-11.868 11.868ZM11.563 20.42a.38.38 0 0 0 .25-.669l-4.535-3.926a.381.381 0 0 0-.499.576l4.535 3.926c.072.063.16.093.249.093ZM17.581 16.057a.38.38 0 1 0 .69-.322l-2.74-5.867a.38.38 0 0 0-.691.322l2.741 5.867ZM14.456 18.036a.38.38 0 1 0 .614-.45L6.67 6.133a.38.38 0 1 0-.614.451l8.4 11.451ZM8.804 44.478a.38.38 0 0 0 .185-.048l5.164-2.885a.381.381 0 0 0-.371-.665l-5.164 2.884a.38.38 0 0 0 .186.714ZM19.664 46.404a.38.38 0 0 0-.533.086l-3.764 5.199a.381.381 0 0 0 .618.447l3.764-5.2a.382.382 0 0 0-.085-.532ZM16.975 43.865a.381.381 0 0 0-.538-.024L6.102 53.317a.381.381 0 0 0 .515.561l10.334-9.475a.381.381 0 0 0 .023-.539ZM46.175 40.88a.38.38 0 1 0-.371.666l5.164 2.885a.38.38 0 1 0 .37-.665l-5.163-2.885ZM40.825 46.489a.381.381 0 0 0-.618.447l3.764 5.199a.38.38 0 0 0 .618-.447l-3.764-5.199ZM53.855 53.317 43.521 43.84a.381.381 0 0 0-.514.562l10.333 9.475a.38.38 0 0 0 .539-.023.381.381 0 0 0-.024-.538ZM53.216 15.864a.38.38 0 0 0-.537-.039l-4.535 3.927a.38.38 0 1 0 .5.576l4.534-3.927a.381.381 0 0 0 .038-.537ZM41.87 16.241a.38.38 0 0 0 .506-.183l2.741-5.867a.38.38 0 0 0-.69-.323l-2.741 5.867a.38.38 0 0 0 .184.506ZM44.968 18.117a.38.38 0 0 0 .532-.082l8.4-11.45a.381.381 0 0 0-.614-.451l-8.4 11.45a.381.381 0 0 0 .082.534Z"
                            />
                          </svg>
                        </div>
                      )}
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <p className="flex items-center space-x-1 truncate text-sm text-gray-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={22}
                              height={22}
                              fill="none"
                            >
                              <path
                                fill="#657488"
                                d="M4.95 19.8c-.454 0-.842-.164-1.165-.492A1.597 1.597 0 0 1 3.3 18.15V6.05c0-.443.162-.829.485-1.157A1.576 1.576 0 0 1 4.95 4.4H6.6V2.2h1.65v2.2h5.5V2.2h1.65v2.2h1.65c.454 0 .842.164 1.165.493.323.328.485.714.485 1.157v12.1c0 .443-.162.829-.485 1.158a1.576 1.576 0 0 1-1.165.492H4.95Zm0-1.65h12.1V9.9H4.95v8.25Zm0-9.9h12.1v-2.2H4.95v2.2Zm6.055 4.95a.81.81 0 0 1-.59-.236.786.786 0 0 1-.24-.584.81.81 0 0 1 .236-.59.786.786 0 0 1 .584-.24.81.81 0 0 1 .59.236c.16.157.24.352.24.584a.81.81 0 0 1-.236.59.786.786 0 0 1-.584.24Zm-3.575 0a.81.81 0 0 1-.59-.236.785.785 0 0 1-.24-.584.81.81 0 0 1 .236-.59.786.786 0 0 1 .584-.24.81.81 0 0 1 .59.236c.16.157.24.352.24.584a.81.81 0 0 1-.236.59.786.786 0 0 1-.584.24Zm7.15 0a.81.81 0 0 1-.59-.236.786.786 0 0 1-.24-.584.81.81 0 0 1 .236-.59.786.786 0 0 1 .584-.24.81.81 0 0 1 .59.236c.16.157.24.352.24.584a.81.81 0 0 1-.236.59.786.786 0 0 1-.584.24Zm-3.575 3.3a.81.81 0 0 1-.59-.236.786.786 0 0 1-.24-.584.81.81 0 0 1 .236-.59.786.786 0 0 1 .584-.24.81.81 0 0 1 .59.236c.16.157.24.352.24.584a.81.81 0 0 1-.236.59.786.786 0 0 1-.584.24Zm-3.575 0a.81.81 0 0 1-.59-.236.785.785 0 0 1-.24-.584.81.81 0 0 1 .236-.59.786.786 0 0 1 .584-.24.81.81 0 0 1 .59.236c.16.157.24.352.24.584a.81.81 0 0 1-.236.59.786.786 0 0 1-.584.24Zm7.15 0a.81.81 0 0 1-.59-.236.786.786 0 0 1-.24-.584.81.81 0 0 1 .236-.59.786.786 0 0 1 .584-.24.81.81 0 0 1 .59.236c.16.157.24.352.24.584a.81.81 0 0 1-.236.59.786.786 0 0 1-.584.24Z"
                              />
                            </svg>
                            <span>
                              {data.INV_InvoiceDate
                                ? moment(data.INV_InvoiceDate).format(
                                  "DD/MM/YYYY"
                                )
                                : `N/A`}
                            </span>
                          </p>
                        </div>
                        <h3 className="mt-1 truncate text-lg font-semibold text-gray-700" style={{fontSize: 'large' }}>
                          {data.reason}
                        </h3>
                        <p className="mt-1 flex items-center space-x-1 truncate text-sm text-gray-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={22}
                            fill="none"
                          >
                            <path
                              stroke="#657488"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1.5}
                              d="M11 8.543c1.09 0 1.98-.889 1.98-1.98 0-1.09-.89-1.98-1.98-1.98-1.091 0-1.98.89-1.98 1.98 0 1.091.889 1.98 1.98 1.98ZM6.224 17.416c1.09 0 1.98-.889 1.98-1.98 0-1.09-.89-1.98-1.98-1.98-1.09 0-1.98.89-1.98 1.98 0 1.091.88 1.98 1.98 1.98ZM15.776 17.416c1.09 0 1.98-.889 1.98-1.98 0-1.09-.89-1.98-1.98-1.98-1.091 0-1.98.89-1.98 1.98 0 1.091.889 1.98 1.98 1.98Z"
                            />
                          </svg>
                          <span>
                            {data.INVPay_TrnType === "P" && "Payment"}
                            {data.INVPay_TrnType === "A" && "Adjustment"}
                            {data.INVPay_TrnType === "C" && "Credit Note"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              ) : anomalieslistloading ? (
                <div className="text-right">
                  <span className="fa fa-spinner fa-spin "></span>
                </div>
              ) : (
                <div className="text-right">No Data Found</div>
              )}
            </ul>
            <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                {/* <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {page === 1
                      ? 1
                      : anomalieData && anomalieData.length > 0
                      ? page !== no_of_pages
                        ? anomalieData.length * (page - 1) + 1
                        : no_of_pages * 16 - anomalieData.length
                      : 0}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {anomalieData && anomalieData.length > 0
                      ? page !== no_of_pages
                        ? anomalieData.length * page
                        : no_of_pages * 16
                      : 0}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {no_of_pages ? no_of_pages * 16 : 0}
                  </span>{" "}
                  results
                </p> */}
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {offset && offset > 0 ? offset : 0}
                    {/* {page === 1
                      ? 1
                      : machine_list && machine_list.length > 0
                      ? page !== no_of_pages
                        ? machine_list.length * (page - 1) + 1
                        : no_of_pages * 9 - machine_list.length
                      : 0} */}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {/* {machine_list && machine_list.length > 0
                      ? page !== no_of_pages
                        ? machine_list.length * page
                        : no_of_pages * 9
                      : 0} */}
                    {upto && upto > 0 ? upto : 0}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {total && total > 0 ? total : 0}
                  </span>{" "}
                  results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md"
                  aria-label="Pagination"
                >
                  <PaginationCustom
                    onClickPrev={() =>
                      page > 1
                        ? (setPage(page - 1), handlePageChange("-"))
                        : null
                    }
                    onClickNext={() =>
                      page < no_of_pages
                        ? (setPage(page + 1), handlePageChange("+"))
                        : null
                    }
                    disabledPrev={page === 1 ? true : false}
                    disabledNext={page === no_of_pages ? true : false}
                  >
                    {renderPageNumbers()}
                  </PaginationCustom>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Anomalies;
