//production
export const apiUrl = "https://api.yello-ai.com";
export const redirectUrl = "https://www.yello-ai.com/login";

//dev & local
// export const apiUrl = process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000" : "https://13.234.217.27";

// export const redirectUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000/login" : "https://d1v8ausnnp4peb.cloudfront.net/login";



export const calenderDays = 120;